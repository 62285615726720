import React, { useEffect, useState } from "react";
import { CameraData } from "../../utils/useCameras";

interface DirectionsProps {
    webcams: CameraData[];
    directionsService: google.maps.DirectionsService | null;
    directionsRenderer: google.maps.DirectionsRenderer | null;
    setSelectedCamera: React.Dispatch<React.SetStateAction<CameraData | null>>;
    apiIsLoaded: boolean;
}

const initMap = (directionsRenderer: google.maps.DirectionsRenderer): google.maps.Map => {
    console.log("Creating map");
    const element = document.getElementById("map");
    if (element != null) {
        console.log(element);
    }
    const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        zoom: 12,
        center: {
            lat: 39.5296,
            lng: -119.8138,
        },
    });
    directionsRenderer.setMap(map);
    console.log("Map created");
    return map;
};

// This component is used to interact with the Google Maps API
// It uses a DirectionsService object along with a DirectionsRenderer object to get and render routes
// Additionally, this component associates a set of waypoint markers with the locations of the
// webcams along the set route
export const Directions: React.FC<DirectionsProps> = ({
    webcams,
    directionsService,
    directionsRenderer,
    setSelectedCamera,
    apiIsLoaded,
}: DirectionsProps) => {
    const [webcamMarkers] = useState<google.maps.Marker[]>([]);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    useEffect(() => {
        if (apiIsLoaded && directionsService != null && directionsRenderer != null) {
            const map = initMap(directionsRenderer as google.maps.DirectionsRenderer);
            setMap(map);
        }
    }, [apiIsLoaded, directionsService, directionsRenderer]);

    useEffect(() => {
        webcamMarkers.map((marker: google.maps.Marker) => {
            marker.setMap(null);
        });
        webcams.map((camera, index) => {
            webcamMarkers[index] = webcamMarkers[index] || new google.maps.Marker();
            webcamMarkers[index].setMap(map);
            const location = new google.maps.LatLng(camera.coordinate.latitude, camera.coordinate.longitude);
            webcamMarkers[index].setPosition(location);
            webcamMarkers[index].setTitle(camera.name);
            webcamMarkers[index].setLabel({
                text: String(index + 1),
                color: "#fff",
            });
            webcamMarkers[index].addListener("click", () => {
                webcamMarkers.map((marker: google.maps.Marker, currentIndex: number) => {
                    marker.setLabel({
                        text: String(currentIndex + 1),
                        color: "#fff",
                    });
                });
                webcamMarkers[index].setLabel({
                    text: String(index + 1),
                    color: "#000",
                });
                map?.panTo(webcamMarkers[index].getPosition() as google.maps.LatLng);
                setSelectedCamera(camera);
            });
        });
    }, [map, webcams]);

    return (
        <div
            className="directions"
            style={{
                alignItems: "center",
                display: "inline",
                position: "relative",
            }}
        >
            <div
                id="map"
                style={{
                    position: "relative",
                    alignItems: "center",
                    margin: "0",
                    height: "100%",
                    width: "100%",
                }}
            ></div>
        </div>
    );
};
