import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// @ts-expect-error "Do not use "@ts-ignore" because it alters compilation errors"
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { CameraData } from "../../utils/useCameras";

interface TwitterScreenProps {
    webcams: CameraData[];
}

interface Handles {
    [index: number]: string;
}

const twitterHandles: Handles = {
    0: "nevadadot",
    2: "CaltransD2",
    3: "CaltransDist3",
    4: "CaltransD4",
    10: "CaltransDist10",
};

// Displays a grid of Twitter feeds based on the on webcam districts
const TwitterScreen = ({ webcams }: TwitterScreenProps): JSX.Element => {
    const [districtIds, setDistrictIds] = useState<number[]>([]);

    useEffect(() => {
        const districts = new Set(webcams.map((cam) => cam.district));
        setDistrictIds(Array.from(districts));
    }, [webcams]);

    return (
        <div>
            <Container>
                <Row>
                    {districtIds.map((id) => (
                        <Col key={id}>
                            {districtIds.length > 0 && (
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName={twitterHandles[id]}
                                    options={{ height: "800" }}
                                />
                            )}
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default TwitterScreen;
