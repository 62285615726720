import React from "react";

// This component is simply a disclaimer stating that we are not responsible for
// anyone's saftey or any damages that may occur while using the app
export const LegalDisclaimer = (): JSX.Element => {
    return (
        <div className="legal-disclaimer">
            <h1>Legal Disclaimer</h1>
            <div className="paragraph">
                <p>
                    SnowPass is intended to keep drivers informed on road conditions. The team is not responsible for
                    any accidents that may occur while driving or using the app.
                </p>
            </div>
        </div>
    );
};
