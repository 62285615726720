import React, { useState, useEffect } from "react";
import { CameraData } from "../../utils/useCameras";
import Webcam from "./WebcamDisplay";
import WeatherCard from "../weather/WeatherCard";

interface WebcamMapCardProps {
    camera: CameraData | null;
    isMobile: boolean;
    hideCamera?: boolean;
}

interface WeatherObject {
    id: number;
    main: string;
    description: string;
    icon: string;
}

export interface WeatherResponse {
    coord: {
        lon: number;
        lat: number;
    };
    weather: WeatherObject[];
    base: string;
    main: {
        temp: number;
        feels_like: number;
        temp_min: number;
        temp_max: number;
        pressure: number;
        humidity: number;
        sea_level: number;
        grnd_level: number;
    };
    visibility: number;
    wind: {
        speed: number;
        deg: number;
        gust: number;
    };
    clouds: {
        all: string;
    };
    rain: {
        "1h": number;
        "3h": number;
    };
    snow: {
        "1h": number;
        "3h": number;
    };
    dt: number;
    sys: {
        type: number;
        id: number;
        message: number;
        country: string;
        sunrise: number;
        sunset: number;
    };
    timezone: number;
    id: number;
    name: string;
    cod: number;
}

// Uses the setter functions to set WeatherData based on lat, lng lookups from the openweather API
const lookupWeatherData = (
    lat: number,
    lng: number,
    setWeatherData: (value: WeatherResponse | null) => void,
    setWeatherError: (value: string | null) => void,
): void => {
    const weatherApiKey = "f1d1c228664364986f92aa3ec9c0dbe1";
    const weatherApiString =
        "https://api.openweathermap.org/data/2.5/weather?lat=" +
        lat +
        "&lon=" +
        lng +
        "&appid=" +
        weatherApiKey +
        "&units=imperial";
    setWeatherError(null);
    fetch(weatherApiString)
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.cod && result.cod != 200) {
                    console.error(result);
                    setWeatherError(result.message);
                } else {
                    console.log(result);
                    setWeatherData(result);
                }
            },
            (error) => {
                console.error(error);
                setWeatherError(error.message);
            },
        );
};

// Displays a stacked card with both Webcam and Weather info using WeatherCard and WebcamDisplay
const WebcamMapCard = ({ camera, isMobile, hideCamera }: WebcamMapCardProps): JSX.Element => {
    const [weatherData, setWeatherData] = useState<WeatherResponse | null>(null);
    const [weatherError, setWeatherError] = useState<string | null>(null);

    useEffect(() => {
        if (camera != null) {
            lookupWeatherData(
                camera.coordinate.latitude,
                camera.coordinate.longitude,
                (value) => {
                    setWeatherData(value);
                },
                (value) => {
                    setWeatherError(value);
                },
            );
        }
    }, [camera]);

    if (camera == null) {
        return <div className={"large-font"}>Webcam not selected</div>;
    }

    let weatherCard;
    if (weatherData != null && weatherError == null) {
        weatherCard = <WeatherCard weatherData={weatherData} isMobile={isMobile} />;
    } else if (weatherError != null) {
        weatherCard = (
            <div>
                <p className="error-message">Failed to fetch weather data</p>
            </div>
        );
    } else {
        weatherCard = <div></div>;
    }

    return (
        <div className={"webcam-map-card"}>
            <b style={{ float: "left" }}>{camera.name}</b>
            <div className={isMobile ? "webcam-map-card-left-mobile" : ""}>
                {hideCamera || <Webcam camData={camera} hideTitle={true} />}
            </div>
            {weatherCard}
        </div>
    );
};

export default WebcamMapCard;
