import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

// This is the Footer component that is rendered at the bottom of the screeen across the whole app
// It contains links to the About Us and Legal Disclaimer pages
export const Footer = (): JSX.Element => {
    return (
        <div className="footer">
            <Card>
                <Card.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <a href="/#/aboutUs">About Us</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <a href="/#/legalDisclaimer">Legal Disclaimer</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>SnowPass 2020-2021</Col>
                        </Row>
                    </Container>
                </Card.Footer>
            </Card>
        </div>
    );
};
