import { Loader } from "@googlemaps/js-api-loader";

// This function is used to initialize the Google Maps API for use across the application
export const loadGoogleAPI = async (
    loader: Loader,
    setGoogleApiIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
    setDirectionsService: React.Dispatch<React.SetStateAction<google.maps.DirectionsService | null>>,
    setDirectionsRenderer: React.Dispatch<React.SetStateAction<google.maps.DirectionsRenderer | null>>,
): Promise<void> => {
    await loader.load();
    setGoogleApiIsLoaded(true);
    console.log("Loading complete!");
    setDirectionsService(new google.maps.DirectionsService());
    setDirectionsRenderer(new google.maps.DirectionsRenderer());
};

// This function is used by the Directions component to request and render a route from the Google Maps API
export const buildRoute = (
    source: string,
    destination: string,
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
    selectCameras: (directions: google.maps.DirectionsResult | null) => void,
): void => {
    if (source != "" && destination != "") {
        console.log("Building route (start: " + source + ", destination: " + destination + ")");
        directionsService.route(
            {
                origin: source,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            function (response, status) {
                if (status == "OK") {
                    console.log("DirectionsService response: OK");
                    selectCameras(response);
                    directionsRenderer.setDirections(response);
                } else {
                    console.log("DirectionsService response: Not OK. Status: " + status);
                    window.alert("Directions request failed due to " + status);
                }
            },
        );
    }
};
