import React, { useEffect, useState } from "react";
import { CameraData } from "../../utils/useCameras";
import Webcam from "./WebcamDisplay";
import "../../styling/ComponentStyle.css";
import grid from "../../images/grid.png";
import list from "../../images/list.png";

interface WebcamScreenProps {
    webcams: CameraData[];
}

// Displays all passed webcams in a toggable list or grid view
const WebcamScreen = ({ webcams }: WebcamScreenProps): JSX.Element => {
    useEffect(() => {
        console.log("Filtered webcams: ");
        console.log(webcams);
    }, [webcams]);

    const [setActive, setActiveState] = useState("");

    function toggleView() {
        setActiveState(setActive === "" ? "-list" : "");
    }

    return (
        <div style={{ alignItems: "right" }}>
            <button className={`toggle-button${setActive}`} onClick={toggleView}>
                <img className="list-image" src={list} />
                <img className="grid-image" src={grid} />
            </button>
            <div className={`webcam-screen${setActive}`}>
                {webcams.map((camData, index) => (
                    <Webcam key={String(index)} camData={camData} />
                ))}
            </div>
        </div>
    );
};

export default WebcamScreen;
