import React, { useState, useEffect } from "react";
import { Form, FormControl } from "react-bootstrap";
import "../../styling/ComponentStyle.css";

export interface SrcDestAutocompleteProps {
    srcAndDestAreInitialized: boolean;
    setSrcAndDestAreInitialized: React.Dispatch<React.SetStateAction<boolean>>;
    setSource: React.Dispatch<React.SetStateAction<string>>;
    setDestination: React.Dispatch<React.SetStateAction<string>>;
    sourceFieldId: string;
    destinationFieldId: string;
    sourceValue: string;
    destinationValue: string;
    apiIsLoaded: boolean;
}

// This component is used to get the source and destination from the user
// It consists of a Form with two fields, start and destination, both of which use the Google API
// to take advantage of their autocomplete functionality
// Each field is wrapped inside of an Autocomplete object that will offer suggestions as the user types
export const SrcDestAutocomplete = ({
    setSrcAndDestAreInitialized,
    setSource,
    setDestination,
    sourceFieldId,
    destinationFieldId,
    sourceValue,
    destinationValue,
    apiIsLoaded,
}: SrcDestAutocompleteProps): JSX.Element => {
    const [sourceAutocomplete, setSourceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [sourceAutocompleteIsSet, setSourceAutocompleteIsSet] = useState<boolean>(false);
    const [destinationAutocomplete, setDestinationAutocomplete] = useState<google.maps.places.Autocomplete | null>(
        null,
    );
    const [destinationAutocompleteIsSet, setDestinationAutocompleteIsSet] = useState<boolean>(false);

    const updateOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("New submission... Updating source and destination");
        setSrcAndDestAreInitialized(true);
        const inputSource = (document.getElementById(sourceFieldId) as HTMLInputElement).value;
        if (inputSource) {
            console.log(inputSource);
            setSource(inputSource);
        } else {
            console.log("Could not find element source-input-field :(");
        }
        const inputDestination = (document.getElementById(destinationFieldId) as HTMLInputElement).value;
        if (inputDestination) {
            console.log(inputDestination);
            setDestination(inputDestination);
        } else {
            console.log("Could not find element destination-input-field :(");
        }
    };

    // config source field autocomplete
    useEffect(() => {
        if (apiIsLoaded && !sourceAutocompleteIsSet) {
            const input = document.getElementById(sourceFieldId) as HTMLInputElement;
            setSourceAutocomplete(new google.maps.places.Autocomplete(input));
            google.maps.event.clearInstanceListeners(input);
            sourceAutocomplete?.setFields(["formatted_address", "geometry", "name"]);
            setSourceAutocompleteIsSet(true);
            console.log(sourceFieldId + " autocomplete set");
        }
    });

    // config dest field autocomplete
    useEffect(() => {
        if (apiIsLoaded && !destinationAutocompleteIsSet) {
            const input = document.getElementById(destinationFieldId) as HTMLInputElement;
            setDestinationAutocomplete(new google.maps.places.Autocomplete(input));
            google.maps.event.clearInstanceListeners(input);
            destinationAutocomplete?.setFields(["formatted_address", "geometry", "name"]);
            setDestinationAutocompleteIsSet(true);
            console.log(destinationFieldId + " field autocomplete set");
        }
    });

    return (
        <Form inline onSubmit={(e) => updateOnSubmit(e)}>
            <FormControl
                type="text"
                placeholder="Start"
                className="mr-sm-2"
                id={sourceFieldId}
                defaultValue={sourceValue}
            />
            <FormControl
                type="text"
                placeholder="Destination"
                className="mr-sm-2"
                id={destinationFieldId}
                defaultValue={destinationValue}
            />
            <button className="go-button" type="submit">
                Go
            </button>
        </Form>
    );
};
