import React, { useEffect, useState } from "react";
import { CameraData } from "../../utils/useCameras";
import WebcamMapCard from "../webcams/WebcamMapCard";
import { useDebouncedEffect } from "../../utils/useDebouncedEffect";

interface WeatherScreenProps {
    webcams: CameraData[];
}

// Displays a list of WebcamMapCards along the path seperated by equal travel distances
const WeatherScreen = ({ webcams }: WeatherScreenProps): JSX.Element => {
    const startingNumToDisplay = 5;
    const weatherApiThrottleTime = 1000;
    const [numToDisplay, setNumToDisplay] = useState<number>(startingNumToDisplay);
    const [tempNumToDisplay, setTempNumToDisplay] = useState<number>(startingNumToDisplay);

    useDebouncedEffect(
        () => {
            setNumToDisplay(tempNumToDisplay);
        },
        weatherApiThrottleTime,
        [tempNumToDisplay],
    );

    useEffect(() => {
        setSliderValue(String(tempNumToDisplay));
    });

    const setSliderValue = (value: string) => {
        const valueHtml = document.getElementById("weather-slider-value");
        if (valueHtml) valueHtml.innerHTML = value;
        setTempNumToDisplay(Number(value));
    };

    return (
        <div className="weather-screen">
            <div className="weather-slide-container">
                <p>
                    Number of weather locations: <span id="weather-slider-value"></span> / {webcams.length}
                </p>
                <input
                    type="range"
                    min="1"
                    max={webcams.length}
                    value={tempNumToDisplay}
                    step="1"
                    className="slider"
                    onInput={(value) => setSliderValue((value.target as HTMLInputElement).value)}
                />
            </div>
            {webcams.map((camera, index) => {
                const modula = Math.ceil(webcams.length / numToDisplay);
                if (index % modula == 0) {
                    return (
                        <div className="weather-screen-card">
                            <WebcamMapCard key={index} camera={camera} isMobile={false} hideCamera={true} />
                        </div>
                    );
                }
                return <div key={index}></div>;
            })}
        </div>
    );
};

export default WeatherScreen;
