import React from "react";
import "./App.css";
import Logo from "./images/snowflake.png";
import { Navbar, Nav } from "react-bootstrap";
import { SrcDestAutocomplete, SrcDestAutocompleteProps } from "./components/nav/SrcDestAutocomplete";

export interface NavProps {
    srcDestprops: SrcDestAutocompleteProps;
}

// Allows the user to navigate between screens
const Navigation = ({ srcDestprops }: NavProps): JSX.Element => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/#/">
                <img src={Logo} width="30" height="30" className="d-inline-block align-top" />
                SnowPass
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/#/">Maps</Nav.Link>
                    <Nav.Link href="/#/twitter">Twitter</Nav.Link>
                    <Nav.Link href="/#/weather">Weather</Nav.Link>
                    <Nav.Link href="/#/webcams">Webcams</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <SrcDestAutocomplete {...srcDestprops} />
        </Navbar>
    );
};

export default Navigation;
