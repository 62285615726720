import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CameraData } from "../../utils/useCameras";
import { Directions } from "./Directions";
import WebcamMapCard from "../webcams/WebcamMapCard";
import "../../styling/ComponentStyle.css";

interface MapScreenProps {
    webcams: CameraData[];
    directionsService: google.maps.DirectionsService | null;
    directionsRenderer: google.maps.DirectionsRenderer | null;
    apiIsLoaded: boolean;
}

// This screen is a wrapper for the Directions component and also contains a component displaying the
// weather info for the selected camera waypoint on the map
// The MapScreen serves as a dashboard for the overall app as it contains route, weather, and webcam
// data
export const MapScreen: React.FC<MapScreenProps> = ({
    webcams,
    directionsService,
    directionsRenderer,
    apiIsLoaded,
}: MapScreenProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const [selectedCamera, setSelectedCamera] = useState<CameraData | null>(null);

    useEffect(() => {
        if (webcams.length > 0) {
            setSelectedCamera(webcams[0]);
        } else {
            setSelectedCamera(null);
        }
    }, [webcams]);

    return (
        <div className="map-screen">
            <div className={isMobile ? "map-screen-right-mobile" : "map-screen-right"}>
                <div className="map-view">
                    <Directions
                        webcams={webcams}
                        directionsService={directionsService}
                        directionsRenderer={directionsRenderer}
                        setSelectedCamera={setSelectedCamera}
                        apiIsLoaded={apiIsLoaded}
                    />
                </div>
            </div>
            <div className={isMobile ? "map-screen-left-mobile" : "map-screen-left"}>
                <WebcamMapCard camera={selectedCamera} isMobile={isMobile}></WebcamMapCard>
            </div>
        </div>
    );
};
