import React from "react";
import { ContributerBio } from "./ContributerBio";
import MichaelPicture from "../../images/Michael.jpg";
import ErylPicture from "../../images/Eryl.jpg";
import CardayellPicture from "../../images/Cardayell.jpg";
import GarrettPicture from "../../images/Garrett.jpg";
import KylePicture from "../../images/Kyle.jpg";
import { Row, Container } from "react-bootstrap";

// This component renders the about us page found in the Footer
// Each member of the team submitted a quick message, a picture, and a link to their websites (if applicable)
// This data is passed into a ContributerBio component that handles each individual
export const AboutUs = (): JSX.Element => {
    const aboutMichael =
        "I'm Michael Martinelli, and I plan to work as a Front-End Software Engineer at Guided Choice upon graduating, concentrating on development of a new multi-platform mobile app. I enjoy many aspects of programming with experience in both front and back end development, but, ultimately, problem solving is what motivates me as a software engineer. Some of my interests outside of technology are watching sports (football and basketball), snowboarding, and all things Star Wars.";
    const aboutEryl =
        "My name is Eryl Kenner, and I am a SSD Validation Engineer, with experience writing system-level validation software to test SSD features. I am fueled by my passion for writing clean and maintainable code and continue to expand my software knowledge and tools in my free time. My diverse experience in areas such as AI, embedded systems, and robotics lends him a unique advantage in understanding and implementing interdisciplinary solutions.";
    const aboutCardayell =
        "I am Cardayell Morgan. I am a Spring 2021 Graduate with a bachelors in Computer Science and Engineering. I am passionate about Front-end development and entrepreneurship.";
    const aboutGarrett =
        "I'm Garrett Moore, and I am graduating in 2021 with a B.S. in Computer Science and a Minor in Mathematics. I'm interested in front-end web development and video game development. In my free time, I like to play basketball, go skiing, and make music.";
    const aboutKyle =
        "I'm Kyle Brainard and I am a Ridgeline Apps Devops Engineer. I started off programming in Unity with C# but since then I've worked with hardware and Python at Intel and then with AWS and React at Ridgeline. I love taking on new challenges and applying myself to code architecture and infrastructure. My hobbies include playing the bass, PC gaming, and all things Tahoe, especially skiing.";

    return (
        <div className="about-us">
            <h1>About Us</h1>
            <Container>
                <Row>
                    <ContributerBio
                        name="Kyle Brainard"
                        picture={KylePicture}
                        github="https://github.com/kylebrain"
                        website="https://www.kylebrain.com/"
                        about={aboutKyle}
                    />
                </Row>
                <Row>
                    <ContributerBio
                        name="Eryl Kenner"
                        picture={ErylPicture}
                        github="https://github.com/ErylKenner"
                        website="http://erylkenner.com/"
                        about={aboutEryl}
                    />
                </Row>
                <Row>
                    <ContributerBio
                        name="Michael Martinelli"
                        picture={MichaelPicture}
                        github="https://github.com/michaelmartinelli15"
                        website="https://michaelmartinelli15.github.io/personalWebsite/"
                        about={aboutMichael}
                    />
                </Row>
                <Row>
                    <ContributerBio
                        name="Garrett Moore"
                        picture={GarrettPicture}
                        github="https://github.com/GarrettMoore22"
                        website="https://michaelmartinelli15.github.io/personalWebsite/"
                        about={aboutGarrett}
                    />
                </Row>
                <Row>
                    <ContributerBio
                        name="Cardayell Morgan"
                        picture={CardayellPicture}
                        github="https://github.com/Cardayellm"
                        website="https://www.cardayell.com/"
                        about={aboutCardayell}
                    />
                </Row>
            </Container>
        </div>
    );
};
