import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactPlayer from "react-player";
import { CameraData } from "../../utils/useCameras";
import "../../styling/ComponentStyle.css";
import chevron from "../../images/chevron.png";

interface WebcamDisplayProps {
    camData: CameraData;
    hideTitle?: boolean;
}

// Displays a webcam feed given a CameraData object
// Handles errors and fullscreen
const WebcamDisplay = ({ camData, hideTitle }: WebcamDisplayProps): JSX.Element => {
    const [cameraDisplayError, setCameraDisplayError] = useState<string | null>(null);

    const fullscreenHandle = useFullScreenHandle();
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        if (isFullscreen == fullscreenHandle.active) {
            return;
        }

        if (!isFullscreen) {
            fullscreenHandle.exit();
        } else {
            fullscreenHandle.enter();
        }
    }, [isFullscreen]);

    useEffect(() => {
        if (isFullscreen != fullscreenHandle.active) {
            setIsFullscreen(fullscreenHandle.active);
        }
    }, [fullscreenHandle.active]);

    useEffect(() => {
        setCameraDisplayError(null);
    }, [camData]);

    const onCameraError = (error: string, data: { details: string }) => {
        const errorInfo = { name: camData.name, error: error, data: data };
        if (data && data.details && (data.details == "manifestLoadTimeOut" || data.details == "manifestLoadError")) {
            console.error(errorInfo);
            setCameraDisplayError(data.details);
        } else {
            if (data && data.details && data.details == "bufferStalledError") {
                // Common error that is handled automatically by HLS
                console.warn("Buffer stalled, ignoring");
            } else {
                console.warn(errorInfo);
            }
        }
    };

    const [setActive, setActiveState] = useState("");

    function toggleAccordion() {
        setActiveState(setActive === "" ? "-active" : "");
    }

    let camRender;
    let camTag = "";

    if (camData.streamingUrl != "" && cameraDisplayError == null) {
        camRender = (
            <div>
                <FullScreen handle={fullscreenHandle}>
                    <ReactPlayer
                        onClick={() => setIsFullscreen((fullscreen) => !fullscreen)}
                        className={"video-stream" + (hideTitle ? "" : " apply-margin")}
                        url={camData.streamingUrl}
                        // Temporary: Garrett plz fix
                        height={isFullscreen || hideTitle ? "100%" : "auto"}
                        width={isFullscreen || hideTitle ? "100%" : "90%"}
                        muted={true}
                        playing={true}
                        controls={false}
                        onError={onCameraError}
                    />
                </FullScreen>
            </div>
        );
    } else if (camData.streamingUrl == "") {
        camTag = " [IMAGE]";
        camRender = <img src={camData.imageUrl} className={"static-image" + (hideTitle ? "" : " apply-margin")} />;
    } else {
        camRender = (
            <div>
                <br />
                <p className="error-message">Camera not responding</p>
            </div>
        );
    }

    if (hideTitle) {
        return camRender;
    }
    return (
        <div className="stream-style">
            <div className="accordion">
                <button className={`accordion-button${setActive}`} onClick={toggleAccordion}>
                    <div className="webcam-label">
                        <img className={`accordion-image${setActive}`} alt="" src={chevron} />
                        {camData.name}
                        {camTag}
                    </div>
                </button>
                <div className={`accordion-content${setActive}`}> {camRender}</div>
            </div>
        </div>
    );
};

export default WebcamDisplay;
