// Module compares the distance between DirectionResult coordinates and CameraData coordinates and generates a list of relavent Camera based on the DirectionResult

import { CameraData, Coordinate } from "./useCameras";
import { getDistance } from "geolib";

export const calculateWebcamsAlongRoute = (
    cameraData: CameraData[],
    directionsResult: google.maps.DirectionsResult,
    distanceThreshold: number,
): CameraData[] => {
    const mappedCameras: [CameraData, number, number][] = cameraData.map((camera) => {
        const [index, distToStart] = isWithinDistanceThreshold(
            camera.coordinate,
            directionsResult.routes[0].overview_path,
            distanceThreshold,
        );
        return [camera, index, distToStart];
    });
    const filteredCameras = mappedCameras.filter((tupleElement) => tupleElement[1] != -1);
    const sortedCameras = filteredCameras.sort((a, b) => {
        if (a[1] < b[1]) {
            return -1;
        }
        if (a[1] > b[1]) {
            return 1;
        }
        // If the cameras are within the same path waypoint, tiebreak using the distance from the start
        if (a[2] < b[2]) {
            return -1;
        }
        if (a[2] > a[1]) {
            return 1;
        }
        return 0;
    });
    return sortedCameras.map((tupleElement) => tupleElement[0]);
};

const isWithinDistanceThreshold = (
    location: Coordinate,
    path_coordinates: google.maps.LatLng[],
    distanceThreshold: number,
): number[] => {
    let foundIndex = -1;
    let minDist = 100000000; //Larger than the radius of the earth so big enough
    const cameraPoint = latLngToCartesian(location.latitude, location.longitude);
    path_coordinates.reduce((prev, current, index) => {
        const start = latLngToCartesian(prev.lat(), prev.lng());
        const end = latLngToCartesian(current.lat(), current.lng());
        let dist;
        if (withinLineSegment(start, end, cameraPoint)) {
            dist = distanceToLine(start, end, cameraPoint);
        } else {
            // Find which endpoint is closer and use the distance to that as the distance
            dist = Math.min(
                getDistance(location, { latitude: current.lat(), longitude: current.lng() }),
                getDistance(location, { latitude: prev.lat(), longitude: prev.lng() }),
            );
        }
        if (dist <= distanceThreshold && dist < minDist) {
            foundIndex = index;
            minDist = dist;
        }
        return current;
    });
    const distanceToStart = getDistance(location, {
        latitude: path_coordinates[0].lat(),
        longitude: path_coordinates[0].lng(),
    });
    return [foundIndex, distanceToStart];
};

const magnitude = (vector: number[]): number => {
    const squareMagnitude = vector[0] * vector[0] + vector[1] * vector[1] + vector[2] * vector[2];
    return Math.sqrt(squareMagnitude);
};

const crossProduct = (vector1: number[], vector2: number[]): number[] => {
    return [
        vector1[1] * vector2[2] - vector1[2] * vector2[1],
        vector1[2] * vector2[0] - vector1[0] * vector2[2],
        vector1[0] * vector2[1] - vector1[1] * vector2[0],
    ];
};

const dotProduct = (vector1: number[], vector2: number[]): number => {
    return vector1[0] * vector2[0] + vector1[1] * vector2[1] + vector1[2] * vector2[2];
};

const withinLineSegment = (start: number[], end: number[], point: number[]): boolean => {
    const direction = [end[0] - start[0], end[1] - start[1], end[2] - start[2]];
    const startToPoint = [point[0] - start[0], point[1] - start[1], point[2] - start[2]];
    const innerProduct = dotProduct(startToPoint, direction);
    return innerProduct >= 0 && Math.sqrt(innerProduct) <= magnitude(direction);
};

const latLngToCartesian = (lat: number, lng: number): number[] => {
    const radiusOfEarth = 6371000.0;
    return [
        radiusOfEarth * Math.cos((lat / 180.0) * Math.PI) * Math.cos((lng / 180.0) * Math.PI),
        radiusOfEarth * Math.cos((lat / 180.0) * Math.PI) * Math.sin((lng / 180.0) * Math.PI),
        radiusOfEarth * Math.sin((lat / 180.0) * Math.PI),
    ];
};

const distanceToLine = (start: number[], end: number[], cameraPoint: number[]): number => {
    const cameraPointMinusStart = [cameraPoint[0] - start[0], cameraPoint[1] - start[1], cameraPoint[2] - start[2]];
    const endMinusStart = [end[0] - start[0], end[1] - start[1], end[2] - start[2]];
    return magnitude(crossProduct(cameraPointMinusStart, endMinusStart)) / magnitude(endMinusStart);
};
