import React, { PropsWithChildren, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SrcDestAutocomplete, SrcDestAutocompleteProps } from "./nav/SrcDestAutocomplete";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

// This class exists to force users to enter a source and destination before attempting to
// view any of the data screens as well as to reduce user confusion about how to start
// Once they enter a set of directions, this component will simply render its child component,
// such as MapScreen or WebcamScreen
export const ScreenWrapper = ({
    srcAndDestAreInitialized,
    setSrcAndDestAreInitialized,
    setSource,
    setDestination,
    sourceFieldId,
    destinationFieldId,
    sourceValue,
    destinationValue,
    apiIsLoaded,
    children,
}: PropsWithChildren<SrcDestAutocompleteProps>): JSX.Element => {
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        if (apiIsLoaded) {
            const startUrl = query.get("start");
            const destinationUrl = query.get("destination");
            if (startUrl != null && destinationUrl != null) {
                setSource(startUrl as string);
                setDestination(destinationUrl as string);
                setSrcAndDestAreInitialized(true);
            }
        }
    }, [apiIsLoaded]);

    useEffect(() => {
        if (apiIsLoaded && sourceValue != "" && destinationValue != "") {
            const params = new URLSearchParams();
            if (params.get("start") == null) {
                params.append("start", sourceValue);
            } else {
                params.set("start", sourceValue);
            }
            if (params.get("destination") == null) {
                params.append("destination", destinationValue);
            } else {
                params.set("destination", destinationValue);
            }
            history.push({ search: params.toString() });
        }
    }, [sourceValue, destinationValue, apiIsLoaded]);

    if (srcAndDestAreInitialized) return <div>{children}</div>;
    else {
        return (
            <div className="initial-input-field">
                <SrcDestAutocomplete
                    srcAndDestAreInitialized={srcAndDestAreInitialized}
                    setSrcAndDestAreInitialized={setSrcAndDestAreInitialized}
                    setSource={setSource}
                    setDestination={setDestination}
                    sourceFieldId={sourceFieldId}
                    destinationFieldId={destinationFieldId}
                    sourceValue={sourceValue}
                    destinationValue={destinationValue}
                    apiIsLoaded={apiIsLoaded}
                />
            </div>
        );
    }
};
