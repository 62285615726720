import { parse } from "fast-xml-parser";
import { useEffect, useState } from "react";

export interface CameraData {
    name: string;
    streamingUrl: string;
    imageUrl: string;
    coordinate: Coordinate;
    district: number;
}

export interface Coordinate {
    latitude: number;
    longitude: number;
}

// Fetches list of CameraData object and provides error handling from NDOT / CalTrans XML files
export const useCameras = (): [CameraData[], boolean, string | null] => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [cameraData, setCameraData] = useState<CameraData[]>([]);

    useEffect(() => {
        const fetchCameraData = async () => {
            try {
                const districtCameraData = await Promise.all([
                    ndotCameraData(),
                    caltransCameraData(2),
                    caltransCameraData(3),
                    caltransCameraData(4),
                    caltransCameraData(10),
                ]);

                const camData = districtCameraData.flat();
                setCameraData(camData);
                setIsLoading(false);
            } catch (error) {
                setError(error);
            }
        };

        fetchCameraData();
    }, []);

    return [cameraData, isLoading, error];
};

interface NdotCameraXmlFormat {
    Name: string;
    StreamingURL: string;
    Location: {
        Lat: string;
        Lon: string;
    };
    FullImageURL: string;
}

// Parses NDOT XML camera file
export const ndotCameraData = async (): Promise<CameraData[]> => {
    //const REMOTE_URL = "https://nvroads.com/services/CameraProxy.svc/cameras/xml";
    const LOCAL_FILE = "./ndotWebcams.xml";
    const camData = fetch(LOCAL_FILE)
        .then((resp) => resp.text())
        .then((textResp) => {
            const xmlData = parse(textResp);
            console.log(xmlData);
            const parsedCamData = xmlData.CameraList.Camera.map((cameraXml: NdotCameraXmlFormat) => {
                //console.log(cameraXml);
                const camera: CameraData = {
                    name: cameraXml.Name,
                    streamingUrl: cameraXml.StreamingURL,
                    imageUrl: cameraXml.FullImageURL,
                    coordinate: {
                        latitude: Number(cameraXml.Location.Lat),
                        longitude: Number(cameraXml.Location.Lon),
                    },
                    district: 0,
                };

                return camera;
            });
            return parsedCamData;
        });

    return camData;
};

interface CaltransCameraXmlFormat {
    location: {
        locationName: string;
        longitude: string;
        latitude: string;
    };
    imageData: {
        streamingVideoURL: string;
        static: {
            currentImageURL: string;
        };
    };
}

// Parses CalTrans XML camera file
export const caltransCameraData = async (district: number): Promise<CameraData[]> => {
    //const REMOTE_URL = "https://cwwp2.dot.ca.gov/data/d3/cctv/cctvStatusD03.xml";
    const LOCAL_FILE = "./caltransD" + district + "Webcams.xml";
    const camData = fetch(LOCAL_FILE)
        .then((resp) => resp.text())
        .then((textResp) => {
            const xmlData = parse(textResp);
            console.log(xmlData);
            const parsedCamData = xmlData.data.cctv.map((cameraXml: CaltransCameraXmlFormat) => {
                const camera: CameraData = {
                    name: cameraXml.location.locationName,
                    streamingUrl: cameraXml.imageData.streamingVideoURL,
                    imageUrl: cameraXml.imageData.static.currentImageURL,
                    coordinate: {
                        latitude: Number(cameraXml.location.latitude),
                        longitude: Number(cameraXml.location.longitude),
                    },
                    district: district,
                };

                return camera;
            });
            return parsedCamData;
        });

    return camData;
};
