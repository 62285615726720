import React from "react";
import { WeatherResponse } from "../webcams/WebcamMapCard";

interface WeatherCardProps {
    weatherData: WeatherResponse | null;
    isMobile: boolean;
}

// Displays card of weather information using the openweather API
const WeatherCard = ({ weatherData, isMobile }: WeatherCardProps): JSX.Element => {
    if (weatherData == null) {
        return <div></div>;
    }
    return (
        <div className={"webcam-map-card-text" + (isMobile ? " webcam-map-card-right-mobile" : "")}>
            <img
                src={"http://openweathermap.org/img/wn/" + weatherData?.weather[0].icon + "@2x.png"}
                style={{ width: "45%", marginLeft: "auto", marginRight: "auto", display: "block" }}
            ></img>
            <p>Temperature: {weatherData?.main.temp} °F</p>
            <p>Wind Speed: {weatherData?.wind.speed} mph</p>
            <p>Description: {weatherData?.weather[0].description}</p>
            {weatherData?.snow != undefined && weatherData?.snow["1h"] && (
                <p>Snow (last 1 hour): {weatherData?.snow["1h"]} mm</p>
            )}
            {weatherData?.rain != undefined && weatherData?.rain["1h"] && (
                <p>Rain (last 1 hour): {weatherData?.rain["1h"]} mm</p>
            )}
            {weatherData?.snow != undefined && weatherData?.snow["3h"] && (
                <p>Snow (last 3 hours): {weatherData?.snow["3h"]} mm</p>
            )}
            {weatherData?.rain != undefined && weatherData?.rain["3h"] && (
                <p>Rain (last 3 hours): {weatherData?.rain["3h"]} mm</p>
            )}
        </div>
    );
};

export default WeatherCard;
