import React from "react";
import { Col, Container, Row } from "react-bootstrap";

interface ContributerBioProps {
    name: string;
    github: string;
    website: string;
    picture: string;
    about: string;
}

// This component is used to render the name, picture, description, and links for each member of the team
// These are used in the AboutUs component that is rendered when users follow the About Us link
export const ContributerBio = ({ name, github, website, picture, about }: ContributerBioProps): JSX.Element => {
    return (
        <Container className="contributer-bio">
            <Row className="align-items-center">
                <Col sm={3}>
                    <img src={picture} className="contributer-picture" />
                </Col>
                <Col className="text-left">
                    <Row>
                        <h5>{name}</h5>
                    </Row>
                    <Row>
                        <a href={github}>Github</a>
                    </Row>
                    <Row>
                        <a href={website}>Website</a>
                    </Row>
                </Col>
                <Col sm={8} className="text-left">
                    <h6>{about}</h6>
                </Col>
            </Row>
        </Container>
    );
};
