import React, { useEffect, useState } from "react";
import "./App.css";
import Nav from "./Nav";
import WebcamScreen from "./components/webcams/WebcamScreen";
import WeatherScreen from "./components/weather/WeatherScreen";
import TwitterScreen from "./components/twitter/TwitterScreen";
import { MapScreen } from "./components/maps/MapScreen";
import { HashRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { CameraData, useCameras } from "./utils/useCameras";
import { calculateWebcamsAlongRoute } from "./utils/webcamFiltering";
import { Loader } from "@googlemaps/js-api-loader";
import { loadGoogleAPI, buildRoute } from "./utils/googleMapsUtils";
import { Footer } from "./components/nav/Footer";
import { AboutUs } from "./components/nav/AboutUs";
import { LegalDisclaimer } from "./components/nav/LegalDisclaimer";
import { SrcDestAutocompleteProps } from "./components/nav/SrcDestAutocomplete";
import { ScreenWrapper } from "./components/ScreenWrapper";

// Renders the app using React Router including the Header and Footer
const App = (): JSX.Element => {
    const [camData, isCamsLoading, camError] = useCameras();
    const [directionsResult, setDirectionsResult] = useState<google.maps.DirectionsResult | null>(null);
    const [filteredCams, setfilteredCams] = useState<CameraData[]>([]);
    const [source, setSource] = useState<string>("");
    const [destination, setDestination] = useState<string>("");
    const [googleApiIsLoaded, setGoogleApiIsLoaded] = useState(false);
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService | null>(null);
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer | null>(null);
    const [srcAndDestAreInitialized, setSrcAndDestAreInitialized] = useState<boolean>(false);

    const selectCameras = (directions: google.maps.DirectionsResult | null): void => {
        setDirectionsResult(directions);
    };

    // load google maps javascript api
    useEffect(() => {
        console.log("Google maps api loading status: " + googleApiIsLoaded);
        if (!googleApiIsLoaded) {
            console.log("Loading google maps api");
            const loader = new Loader({
                apiKey: "AIzaSyAV_L2rqQaq6RjourEZW1_8AuIOFnGT3yg",
                version: "weekly",
                libraries: ["places"],
            });
            loadGoogleAPI(loader, setGoogleApiIsLoaded, setDirectionsService, setDirectionsRenderer);
        }
    }, [googleApiIsLoaded]);

    // build route when src/dest change
    useEffect(() => {
        console.log("Start: " + source + ", Destination: " + destination);
        if (directionsService != null && directionsRenderer != null) {
            buildRoute(
                source,
                destination,
                directionsService as google.maps.DirectionsService,
                directionsRenderer as google.maps.DirectionsRenderer,
                selectCameras,
            );
        }
    }, [source, destination, directionsService, directionsRenderer]);

    useEffect(() => {
        if (camError) {
            console.log("Failed to load cameras, cannot select camera along path. Encountered: " + camError);
            return;
        }

        if (directionsResult && !isCamsLoading) {
            console.log("Route and cameras loaded!");
            setfilteredCams(calculateWebcamsAlongRoute(camData, directionsResult, 200));
        }
    }, [directionsResult, isCamsLoading, camError]);

    const srcDestProps: SrcDestAutocompleteProps = {
        srcAndDestAreInitialized: srcAndDestAreInitialized,
        setSrcAndDestAreInitialized: setSrcAndDestAreInitialized,
        setSource: setSource,
        setDestination: setDestination,
        sourceFieldId: "",
        destinationFieldId: "",
        sourceValue: source,
        destinationValue: destination,
        apiIsLoaded: googleApiIsLoaded,
    };

    const navProps = {
        ...srcDestProps,
        sourceFieldId: "nav-source-field",
        destinationFieldId: "nav-destination-field",
    };
    const wrapperProps = {
        ...srcDestProps,
        sourceFieldId: "wrapper-source-field",
        destinationFieldId: "wrapper-destination-field",
    };

    return (
        <HashRouter>
            <div className="App">
                <Nav srcDestprops={navProps} />
                <Switch>
                    <Route
                        path="/webcams"
                        render={() => (
                            <ScreenWrapper {...wrapperProps}>
                                <WebcamScreen webcams={filteredCams} />
                            </ScreenWrapper>
                        )}
                    />
                    <Route
                        path="/weather"
                        render={() => (
                            <ScreenWrapper {...wrapperProps}>
                                <WeatherScreen webcams={filteredCams} />
                            </ScreenWrapper>
                        )}
                    />
                    <Route
                        path="/twitter"
                        render={() => (
                            <ScreenWrapper {...wrapperProps}>
                                <TwitterScreen webcams={filteredCams} />
                            </ScreenWrapper>
                        )}
                    />
                    <Route path="/aboutUs" component={AboutUs} />
                    <Route path="/legalDisclaimer" component={LegalDisclaimer} />
                    <Route
                        render={() => (
                            <ScreenWrapper {...wrapperProps}>
                                <MapScreen
                                    webcams={filteredCams}
                                    directionsService={directionsService}
                                    directionsRenderer={directionsRenderer}
                                    apiIsLoaded={googleApiIsLoaded}
                                />
                            </ScreenWrapper>
                        )}
                    />
                </Switch>
                <Footer />
            </div>
        </HashRouter>
    );
};

export default App;
